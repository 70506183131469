.sv3-table-container {
  width: fit-content;
  max-width: 100%;
  overflow-x: scroll;
  border: 1px solid rgb(82, 116, 164);
  border-radius: 7.5px;
  // height: 100%;
  margin-bottom: 10px;
}

.sv3-table-title {
  margin-bottom: 5px;
}

.sv3-table-viewer {
  display: inline-block;
  width: auto;
  // background-color: var(--theme-main2);
  // overflow: scroll;
}

.sv3-table {
  min-width: 500px;
  max-width: 100% !important;
  width: auto;
  table-layout: auto;
  border-collapse: collapse;
  margin-bottom: 0;
  border: none;
  font-size: 14px;
}

.sv3-table *::selection {
  background-color: rgba(255, 255, 255, 0.1);
}

.sv3-table *::-moz-selection {
  background-color: rgba(255, 255, 255, 0.1);
}

.sv3-table-head * {
  // font-weight: bold;
  border: none;
  font-style: italic;
  opacity: 0.75;
}

.sv3-table-head th, .sv3-table-body td {
  // padding: 12px;
  text-align: left;
}

.sv3-table td {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sv3-table tr {
  border-bottom: 1px solid rgb(55, 60, 65) !important;
  // border-bottom: 1px solid red !important;
}

.sv3-table td {
  border: none !important;
  // border-bottom: 0.5px solid rgb(55, 60, 65);
}

.sv3-table-body tr:last-child {
  border-bottom: none !important;
}
// .sv3-table-head tr > * {
//   background-color: rgba(255, 255, 255, 0.055) !important;
// }

// .sv3-table tr:nth-child(even) > td:nth-child(odd) {
//   background-color: rgba(255, 255, 255, 0.025);
// }

// .sv3-table tr:nth-child(odd) > td:nth-child(even) {
//   background-color: rgba(255, 255, 255, 0.025);
// }