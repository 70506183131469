.sv3-smp-progress-bar {
  width: 100%;
  position: relative;
  border-radius: 10px;
}

.sv3-smp-progress-bar .progress {
  position: absolute;
  max-width: 100%;
  top: 0;
  left: 0;
  max-height: 100%;
  height: 100%;
  width: 0%;
  border-radius: 10px;
}

.sv3-smp-progress-bar .progress:hover {
  cursor: pointer;
}

.sv3-smp-progress-bar .highlight:hover {
  box-sizing: content-box;
  border: 1.5px solid rgb(200, 200, 200);
  top: -1.5px;
  left: -1.5px;
}