.sndbx-chat-container {
  position: relative;
  text-align: center;
  max-width: 100%;
  height: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  --global-font-family: "Inter", serif;
  // --secondary-font-family: "Poppins", sans-serif;
  --secondary-font-family: "Noto Serif", serif;
}

.sndbx-content-body {
  width: 100%;
  height: calc(100% - 147px);
  display: flex;
  flex-direction: column-reverse;
  overflow: auto;
  scroll-behavior: smooth;
  position: relative;
  padding: 20px 10px !important;
}

.sndbx-content-body::-webkit-scrollbar {
  width: 3px;
}

.sndbx-content-body::-webkit-scrollbar-track {
  // background-color: rgba(0, 0, 0, 0.3);
  background-color: rgba(255, 255, 255, 0);
  border-radius: 3px;
}

.sndbx-content-body::-webkit-scrollbar-thumb {
  // background-color: rgba(0, 0, 0, 0.5);
  background-color: rgba(255, 255, 255, 0.5);
  outline: none;
  border-radius: 5px;
}

.wrapper-style .sndbx-chat-container {
  max-width: 800px;
}

.sndbx-convo-base {
  width: 100%;
  height: fit-content;
  text-align: left;
  margin: auto;
  max-width: 810px;
  position: relative;
  margin-bottom: 15px;
  padding-left: 24px;
}

.sndbx-convo-base {
  .quote-blk {
    position: absolute;
    font-size: 16px;
    line-height: 24px;
    left: 0px;
  }
  .convo-control {
    position: absolute;
    font-size: 15px;
    bottom: -1px;
    left: -2px;
    padding-left: 22px;
    display: none;
    width: 100%;
    .ctrl-btn {
      margin-right: 3px;
      padding: 2px 5px;
      opacity: 0.6;
    }
    .ctrl-btn:hover {
      cursor: pointer;
      opacity: 0.95;
    }
  }
}

.sndbx-convo-base:hover {
  .convo-control {
    display: block;
  }
}

.sndbx-convo-user {
  font-size: 16px;
  // font-family: "Poppins", sans-serif;
  font-family: var(--global-font-family);
  font-weight: 500;
  line-height: 1.5;
  color: rgb(168, 206, 242);
  margin-top: 10px;
  white-space: pre-wrap;
}

.sndbx-convo-rply {
  position: absolute;
  left: 7px;
  bottom: calc(100% - 2px);
  font-size: 10px;
  // font-family: "Poppins", sans-serif;
  font-family: var(--global-font-family);
  font-weight: 600;
  font-style: italic;
  opacity: 0.8;
  display: inline-flex;
  align-items: center;
  // color: orange;
  .rply-smbl {
    margin-top: 8px;
    display: inline-block;
    width: 30px;
    height: 12px;
    border: 2px solid rgb(137, 165, 192);
    // border: 2px solid orange;
    border-bottom: none;
    border-right: none;
    border-radius: 5px 0 0 0;
    opacity: inherit;
  }
}

.sndbx-convo-assistant {
  font-size: 13px;
  // font-family: "Poppins", sans-serif;
  font-family: var(--global-font-family);
  font-weight: 500;
  line-height: 1.4;
  // color: rgb(190, 190, 190);
  color: rgb(245, 245, 245);
  padding-bottom: 25px;
}

// .sndbx-convo-assistant:first-child {
//   margin-bottom: 40px;
// }

.sndbx-intro {
  font-size: 32px;
  width: 100%;
  max-width: 800px;
  margin: auto;
  height: 100%;
  text-align: left;
  font-weight: 700;
  display: flex;
  align-items: center;
  // font-family: "Poppins", sans-serif;
  font-family: var(--global-font-family);
  color: rgb(200, 200, 200);
  padding-left: 20px;
}

@media only screen and (max-width: 600px) {
  .sndbx-intro {
    padding-left: 0px;
  }
}


.wrapper-style .sndbx-intro {
  padding-left: 5px;
}

.markdown-wrapper pre {
  font-size: 13px !important;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 0;
  font-family: 'Roboto Mono';
  padding: 10px 13px !important;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.markdown-wrapper code {
  font-size: 13px;
  border-radius: 0;
  font-family: 'Roboto Mono';
  padding: 0 !important;
  margin: 0 !important;
}

.markdown-wrapper ul, .markdown-wrapper ol {
  padding-left: 22px; // default indentation
  strong {
    font-weight: 600;
  }
}

.markdown-wrapper img {
  border-radius: 5px !important;
}

.markdown-wrapper ol {
  list-style-type: decimal !important;
}

.markdown-wrapper ol li::marker {
  content: counter(list-item) ". ";
}

.markdown-wrapper ul li, .markdown-wrapper ol li {
  padding: 4px 0px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.16px;
}

.markdown-wrapper ul li:first-child,
.markdown-wrapper ol li:first-child {
  padding-top: 6px;
}

.markdown-wrapper ul li:last-child,
.markdown-wrapper ol li:last-child {
  padding-bottom: 6px;
}

.markdown-wrapper li {
  list-style: disc;
  // list-style: none;
}

.markdown-wrapper a {
  color: rgba(var(--sndbx-auto-fm-url-clr)) !important;
}

.markdown-wrapper a:visited {
  color: rgba(var(--sndbx-auto-fm-url-clr));
}

.markdown-wrapper a:hover {
  filter: brightness(115%);
  text-decoration: underline;
}

// scroll bar styles
.trackY {
  width: 5px !important;
}


// ######## new markdown element styling starts here ########

.markdown-wrapper> :first-child {
  padding-top: 0 !important;
  margin-top: 0 !important;
}

.markdown-wrapper>hr:first-child {
  // padding-top: 6px !important;
  margin-top: 16px !important;
}

.markdown-wrapper hr {
  margin: 6px 0;
  height: 1.2px;
  border: none;
}

.markdown-wrapper p {
  padding: 6px 0;
  font-size: 16px;
  line-height: 24px !important;   // 1.5
  font-weight: 400;
  letter-spacing: -0.16px;
}

.markdown-wrapper li p {
  padding: 0;
}

.markdown-wrapper h1 {
  padding: 4px 0;
  margin: 32px 0 0 0;
  font-family: var(--secondary-font-family);
  font-size: 28px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: -0.56px;
}

.markdown-wrapper h2 {
  padding: 4px 0;
  margin: 24px 0 0 0;
  font-family: var(--secondary-font-family);
  font-size: 22px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: -0.44px;
}

.markdown-wrapper h3 {
  padding: 4px 0;
  margin: 20px 0 0 0;
  font-family: var(--secondary-font-family);
  font-size: 19px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: -0.39px;
}

.markdown-wrapper h4 {
  padding: 4px 0;
  margin: 18px 0 0 0;
  font-family: var(--secondary-font-family);
  font-size: 17px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.33px;
}

.markdown-wrapper h5 {
  padding: 4px 0;
  margin: 16px 0 0 0;
  font-family: var(--secondary-font-family);
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: -0.30px;
}

.markdown-wrapper h1+h1,
.markdown-wrapper h1+h2,
.markdown-wrapper h1+h3,
.markdown-wrapper h1+h4,
.markdown-wrapper h1+h5,
.markdown-wrapper h2+h1,
.markdown-wrapper h2+h2,
.markdown-wrapper h2+h3,
.markdown-wrapper h2+h4,
.markdown-wrapper h2+h5,
.markdown-wrapper h3+h1,
.markdown-wrapper h3+h2,
.markdown-wrapper h3+h3,
.markdown-wrapper h3+h4,
.markdown-wrapper h3+h5,
.markdown-wrapper hr+h1,
.markdown-wrapper hr+h2,
.markdown-wrapper hr+h3,
.markdown-wrapper hr+h4,
.markdown-wrapper hr+h5 {
  margin-top: 0;
}

.markdown-wrapper *::selection {
  background-color: rgba(255, 255, 255, 0.09);
}

.markdown-wrapper *::-moz-selection {
  background-color: rgba(255, 255, 255, 0.09);
}