.sv2-prvw-root {
  width: 100%;
  // height: 200px;
  // border-radius: 5px;
  // overflow: hidden;
  // margin-top: 10px;
  // margin-bottom: 10px;
  // background-color: var(--theme-main3);
  // padding: 10px;
  // line-height: 1;
}

.sv3-prvw-title {
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  // align-items: center;
  align-items: flex-end;
}

.sv3-prvw-title .button-container {
  display: flex;
  gap: 1.5px;
  margin-right: 2.5px;
  margin-bottom: 2.5px;
  background-color: rgb(43, 48, 56);
  border-radius: 15px;
  // padding: 1px;
  overflow: hidden;
  border: 1.5px solid rgb(43, 48, 56);
}

/* Optional: Basic button styling */
.sv3-prvw-title .button-container .prvw-toggle {
  font-style: italic;
  color: rgba(245, 245, 245, 0.7);
  font-size: 12px;
  padding: 3px 15px;
  border-radius: 15px;
  // border: 1px solid rgb(39, 45, 53);
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.sv3-prvw-title .button-container .selected {
  color: rgb(245, 245, 245);
  background-color: rgb(30, 34, 40);
}


// .sv3-prvw-title .button-container button:hover {
//   background-color: rgb(39, 45, 53);
// }

.sv3-prvw-basic-txt {
  color: rgb(230, 230, 240);
  border-radius: 12px;
  width: 100%;
  padding: 0px 15px;
  background-color: rgb(30, 34, 40);
  border: 1.5px solid rgb(43, 48, 56);
}

// .sv3-prvw-basic-txt li::marker {
//   content: ✽ !important;
//   color: red;
// }

.sv3-prvw-txt-wrapper {
  padding: 10px 15px;
  white-space: pre-line;
  line-height: 1.5;
  font-family: "JetBrains Mono", monospace;
}

.sv3-prvw-basic-txt::selection {
  background-color: rgba(255, 255, 255, 0.09);
}

.sv3-prvw-basic-txt::-moz-selection {
  background-color: rgba(255, 255, 255, 0.09);
}